.geegstack-faq {
  margin: auto;
  padding: 50px 10% 100px;
  background-color: #eeeeeef3;
}

.geegstack-faqp {
  text-align: center;
  width: 60%;
  margin: auto;
}
.faq {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
.faq-li {
  width: 45%;
  margin: 10px 0;
}
.faq-q {
  font-size: 20px;
  font-weight: 700;
  margin: 10px 0;
}
.answers,
.answers li {
  list-style: disc;
}
.answers li {
  margin-left: 1rem;
}
.faq-btn {
  background-color: #28918a;
  color: white;
  height: 60px;
  padding: 0.5rem 1rem;
  outline: none;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600;
  margin: 1rem 0;
  display: inline-block;
}

@media (max-width: 58rem) {
  .geegstack-faqp {
    width: 100%;
  }
  .faq-li {
    width: 100%;
  }
}
