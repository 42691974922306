@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --fs--300: 0.8rem;
  --fs-400: 0.9rem;
  --fs-450: 0.95rem;
  --fs-500: 1rem;
  --fs-600: 1.2rem;
  --fs-h1: 2.6rem;
  --fs-h2: 2.4rem;
  --fs-h3: 1.8rem;
  --fs-4: 1.7rem;
  --fs-xl: clamp(3.5rem, 10vw + 1rem, 10rem);
}
body {
  font-family: "Open Sans", sans-serif;
  font-size: var(--fs-500);
  font-weight: 500;
}
li {
  list-style-type: none;
}
a {
  text-decoration: none;
}
h1 {
  font-size: var(--fs-h1);
}
h2 {
  font-size: var(--fs-h2);
}
h3 {
  font-size: var(--fs-h3);
}

@media (max-width: 107rem) {
  :root {
    --fs--300: 0.75rem;
    --fs-400: 0.85rem;
    --fs-450: 0.9rem;
    --fs-500: 0.95rem;
    --fs-600: 1.5rem;
    --fs-h1: 2.5rem;
    --fs-h2: 2rem;
    --fs-h3: 1.75rem;
    --fs-4: 1.5rem;
    --fs-xl: clamp(3.5rem, 10vw + 1rem, 10rem);
    /* --fs-xl: clamp(4rem, 12vw + 1rem, 12rem); */
  }
}

@media (max-width: 89rem) {
  :root {
    --fs--300: 0.7rem;
    --fs-400: 0.8rem;
    --fs-450: 0.85rem;
    --fs-500: 1rem;
    --fs-600: 1rem;
    --fs-h1: 2rem;
    --fs-h2: 1.75rem;
    --fs-h3: 1.25rem;
    --fs-4: 1rem;
    /* --fs-xl: clamp(4rem, 12vw + 1rem, 12rem); */
  }
}
