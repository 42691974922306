.track-timeline {
  margin: auto;
  padding: 50px 10% 100px;
  background-color: #eeeeeef3;
}
.track-timeline-header {
  font-weight: 700;
  font-size: 1.1rem;
}
.track-timelines-items {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  flex-wrap: wrap;
}
.track-timelines-item {
  width: 45%;
  line-height: 30px;
  margin: 15px 0px;
}

.track-timeline-img {
  width: 100%;
  position: relative;
  height: 700px;
  background: #28918a url(../images/geegstack-timeline.jpeg) 1% 20% / cover
    no-repeat;
  border-radius: 10px;
}
.track-timeline-btn {
  background-color: #28918a;
  color: white;
  height: 60px;
  padding: 0.5rem 1rem;
  outline: none;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600;
  margin: 1rem 0;
}

@media (max-width: 58rem) {
  .track-timelines-items {
    flex-direction: column;
  }
  .track-timelines-item {
    width: 100%;
  } 
}
