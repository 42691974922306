.BecomeProfessional {
  padding: 50px 10% 100px;
  margin: auto;
  background-color: #eeeeeef3;
}
.BecomeProfessional h1 {
  text-align: center;
  margin: 30px 0;
  font-size: 2rem;
}
.becomeprofcontent h1::after {
  content: "";
  display: block;
  width: 100px;
  height: 5px;
  background-color: #28918a;
  margin: 10px auto;
}
.becomeprofessional {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.becomeprofcontent {
  width: 45%;
}
.becomeprofcontent p {
  margin: 1rem 0;
  line-height: 30px;
}
.becomeprofimg {
  width: 50%;
  position: relative;
  height: 600px;
  background: #28918a url(../images/dev-geegstack.jpeg) 90% 10% / cover
    no-repeat;
  border-radius: 10px;
}
/* .becomeprofimg img {
  width: 100%;
  height: 100%;
} */
.becomeprofessionbtn {
  background-color: #28918a;
  color: white;
  height: 60px;
  padding: 0.5rem 1rem;
  outline: none;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600;
}

@media (max-width: 58rem) {
  .becomeprofessional {
    flex-direction: column-reverse;
  }

  .becomeprofimg {
    width: 100%;
  }

  .becomeprofcontent {
    width: 100%;
  }
  .becomeprofimg {
    height: 400px;
  }
}
