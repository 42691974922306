.features {
  width: 80%;
  margin: auto;
  display: flex;
  text-align: center;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 60px 0px;
}
.feature {
  /* width: 300px; */
  width: 45%;
  margin: 1rem;
  min-width: 350px;
  max-width: 500px;
}

.feature-title {
  font-weight: bold;
  font-size: var(--fs-600);
}
.feature-img-wrapper {
  width: 200px;
  /* height: 200px; */
  margin: auto;
}
.feature-img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}


@media (max-width: 65rem) {
  .features {
    justify-content: center;
  }
}