.learn-env {
  width: 80%;
  margin: auto;
  padding: 70px 0px;
}
.learn-env-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.learn-env h1 {
  text-align: center;
  margin-bottom: 30px;
}
.learn-env-img {
  width: 60%;
  position: relative;
  height: 600px;
  background: #28918a url(../images/geegstack-learn-env.jpg) 90% 10% / cover
    no-repeat;
  border-radius: 10px;
}
.learn-env-text {
  position: absolute;
  width: 43%;
  background: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 2rem;
  z-index: 2;
  right: 0;
  border-radius: 10px;
}
.learning-env-feature {
  font-weight: 700;
}
.link a {
  color: #28918a;
}

@media (max-width: 58rem) {
  .learn-env-content {
    position: static;
    flex-direction: column;
  }
  .learn-env-img, .learn-env-text{
    position: static;
    width: 100%;
    margin-bottom: 30px;
    height: 400px;
  }
}
