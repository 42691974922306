.testimonials {
  padding: 30px 10%;
  margin: auto;
  display: flex;
  text-align: center;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 30px;
  background-color: #eeeeeef3;
}
.testimonial {
  /* width: 300px; */
  width: 45%;
  margin: 2rem 1rem;
  min-width: 350px;
  max-width: 600px;
}
.testimonial-name {
  font-weight: bold;
  /* font-size: var(--fs-600); */
  font-size: 1.2rem;
}
.testimonial-text {
  margin: 1rem 0;
}
.testimonial-skill {
  font-weight: normal;
}
.testimonial-img-wrapper {
  width: 150px;
  height: 150px;
  margin: auto;
}
.testimonial-img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}
