.nav {
  display: flex;
  width: 80%;
  justify-content: space-between;
  align-items: center;
  padding: 1.3rem 0;
  margin: 0 auto;
}
.logo {
  width: 30%;
}
.nav-items {
  display: flex;
  /* text-align: center; */
  justify-content: flex-end;
  align-items: center;
  width: 60%;
  /* padding: 1rem; */
}
.nav-item {
  margin-left: 1.5rem;
}

.nav-item a {
  color: #095a54;
  font-weight: 600;
}

.hamburger {
  cursor: pointer;
  display: none;
}

.bar {
  display: block;
  width: 25px;
  height: 4px;
  margin: 5px auto;
  transition: all 0.3s ease-in-out;
  background-color: #28918a;
  -webkit-transition: all 0.3s ease-in-out;
}

.nav-items-mobile {
  left: -110%;
  transition: 0.3s;
  display: none;
}

@media (max-width: 58rem) {
  /* Nav  */
  .nav {
    width: 100%;
    padding: 0 5%;
    position: fixed;
    z-index: 10;
    background-color: white;
    height: 12vh;
  }

  /* Nav item */
  .nav-items-mobile {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    z-index: 10;
    margin-top: 12vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .nav-items-mobile li{
    font-weight: 700;
    text-align: center;
    margin: 2rem 0;
    font-size: 1.5rem;
  }
  .nav-items-mobile.active {
    left: 0;
  }
  .nav-items-mobile li a{
    color: #28918a; 
  }
  /*Hambuger  */
  .hamburger {
    display: block;
    /* position: fixed; */
  }
  .hamburger.active {
  }
  .hamburger.active .bar:nth-child(2) {
    opacity: 0;
  }
  .hamburger.active .bar:nth-child(1) {
    transform: translateY(9px) rotate(45deg);
  }
  .hamburger.active .bar:nth-child(3) {
    transform: translateY(-9px) rotate(-45deg);
  }

  .website-nav-links {
    display: none;
  }
  /*  */
  .nav-items {
    display: none;
  }
}
