.main {
}
p {
  line-height: 30px;
}

.course-info-regis-ctn {
  width: 100%;
  position: relative;
  height: 750px;
  background: #28918a url(../images/geegstack-academy-course.png) 50% 50% /
    cover no-repeat;
}

.course-info-regis-content {
  width: 80%;
  position: relative;
  display: flex;
  margin: auto;
  justify-content: space-between;
  color: white;
  padding-top: 100px;
  align-items: center;
}
.overlay {
  background-color: #134544;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0.85;
}
.student-experience {
  width: 50%;
  margin: 30px auto;
  text-align: center;
  max-width: 600px;
}

.get-pack {
  width: 40%;
  margin: 30px auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #28918a;
  padding: 1rem;
  border-radius: 10px;
  flex-wrap: wrap;
}
.get-pack p {
  color: white;
  width: 60%;
}
.get-pack button {
  background-color: white;
  color: #28918a;
  height: 60px;
  padding: 0.5rem 1rem;
  outline: none;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600;
  margin: 1rem 0;
  display: inline-block;
}

.cancel {
  margin: 1rem 2rem 1rem 0;
  position: absolute;
  top: 0;
  right: 0;
}
.bar {
  display: block;
  width: 25px;
  height: 4px;
  margin: 5px auto;
  background-color: #28918a;
}

.first_bar {
  transform: translateY(9px) rotate(45deg);
}
.second_bar {
  transform: translateY(-9px) rotate(-45deg);
}
.third_bar {
  opacity: 0;
}

/*Course Info*/
.course_info {
  width: 45%;
}
.course-name {
  margin-bottom: 0.8rem;
  text-align: unset;
}
.course-description {
  margin: 1rem 0;
  font-size: 16px;
  margin-bottom: 2rem;
}
.enrollement-details {
  width: 90%;
}
.enrollement-details-content h3 {
  margin: 1rem 0;
  font-size: 1.2rem;
}
.enrollement-detail {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
}

.ws-icon-link {
  display: inline-block;
  position: fixed;
  bottom: 50px;
  right: 20px;
  z-index: 30;
  animation: bouncingIcon 1s linear infinite backwards;
}

@keyframes bouncingIcon {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(10px);
  }
}

/* Mobile responsiveness */

@media (max-width: 89rem) { 
  .course_info {
    width: 100%;
  }

}
@media (max-width: 65rem) {
  .course-info-regis-ctn {
    height: unset;
    padding-bottom: 30px;
  }
  .course-info-regis-content {
    flex-direction: column;
  }
}
@media (max-width: 58rem) {
  .student-experience{
    width: 80%;
  }

  .get-pack {
    width: 80%;
  } 
 
}

@media (max-width: 42rem) {
 .get-pack p {
    width: 100%;
  }

}
