table {
    border-collapse: collapse;
    width: 100%;

  }
  
  th, td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #DDD;
  }
  .table-container {
    width: 90%;
    margin: 50px auto;
    max-width: 900px;
    border: 2px solid #28918a;
  }
  tr:hover {background-color: #D6EEEE;}