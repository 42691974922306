.geegstack-career-support {
  width: 80%;
  margin: auto;
}
h1 {
  text-align: center;
  margin: 30px 0;
}
.geegstack-career-support p {
  width: 50%;
  margin: 1rem 0;
  text-align: center;
  margin: auto;
}

@media (max-width: 58rem) {
  .geegstack-career-support p {
    width: 100%;
  } 
}
