.register {
  /* width: 43.8125rem; */
  background-color: white;
  color: #28918a;
  border-radius: 5px;
  padding: 2.5rem;
  width: 45%;
  min-width: 28rem;
}

.register h3 {
  margin-bottom: 1rem;
  width: 80%;
  font-size: 1.2rem;
}

.register_name {
  display: flex;
  justify-content: space-between;
}
.register_name div {
  width: 48%;
}

/* .register_form {
  width: 80%;
} */

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #28918a;
  height: 60px;
  background-color: white;
  margin: auto;
  border-radius: 5px;
  outline: none;
}
.input-wrapper {
  margin: 2rem 0;
}
.submit button {
  display: inline-block;
  width: 100%;
  height: 60px;
  font-size: 16px;
  border-radius: 10px;
  background-color: #28918a;
  outline: none;
  border: none;
  color: white;
  cursor: pointer;
}

@media (max-width: 65rem) {
  .custom {
    width: 100%;
    padding: 2rem;
  }
  .register h3 {
    width: 100%;
  }
}
